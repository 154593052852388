<template>
  <v-popup v-model:visible="openForm" overlay closeOverlay keyPopup="popupPolicyForm" :closeable="false">
    <template #header class="relative">
      <div class="w-full">
                <span
                    class="i-custom-close2 w-3 h-3 text-bw-24 absolute z-5 top-4.5 right-4.5 cursor-pointer"
                    @click="closeForm"
                ></span>
        <div>
          <h3 class="text-center text-30px leading-44px font-[550]">
            {{ $t('CHECK') }}
            <span class="text-primary">
                            {{ $t('CONTRACT_INFORMATION') }}
                        </span>
          </h3>
          <p class="text-center text-black/60 text-15px leading-6 mt-3 font-normal">
            {{ $t('PLEASE_FILL_IN_THE_REQUIRED_INFORMATION') }}
          </p>
        </div>
      </div>
    </template>
    <div class="my-7.5">
      <form @submit.prevent="handleSubmit" class="flex flex-col gap-3.5">
        <div class="flex flex-col gap-1">
          <label
              for="name_popup_policy"
              :class="errorPhone ? '!text-red-500' : ''"
              class="text-bw-26 text-13px leading-6 font-semibold uppercase"
          >
            {{ t('PHONE_NUMBER') }}
          </label>
          <input
              id="name_popup_policy"
              :class="errorPhone ? '!placeholder:text-red-500/80 border-red-500 !text-red-500' : ''"
              class="border border-#CBCBCB font-medium outline-none w-full rounded-28px py-15px text-15px px-5.5 text-black/80 placeholder-bw-12/50 placeholder-font-semibold h-13.5"
              type="text"
              :placeholder="$t('ENTER_YOUR_PHONE_NUMBER')"
              v-model="phone"
              @input="checkPhone()"
              @blur="checkPhone()"
          />
          <Transition name="fade">
                        <span class="text-red-500 pl-5 mt-2" v-if="errorPhone">
                            {{ $t('INCORRECT_PHONE_NUMBER') }}
                        </span>
          </Transition>
        </div>
        <div class="flex flex-col gap-1">
          <label
              for="cccd_popup_policy"
              :class="errorCCCD ? '!text-red-500' : ''"
              class="text-bw-26 text-13px leading-6 font-semibold uppercase"
          >
            {{ t('YOUR_IDENTITY') }}
          </label>
          <input
              for="cccd_popup_policy"
              :class="errorCCCD ? '!placeholder:text-red-500/80 border-red-500 !text-red-500' : ''"
              class="border border-#CBCBCB font-medium outline-none w-full rounded-28px py-15px text-15px px-5.5 text-black/80 placeholder-bw-12/50 placeholder-font-semibold h-13.5"
              id="full_name"
              name="full_name"
              type="text"
              :placeholder="t('ENTER_IDENTITY')"
              v-model="cccd"
              @input="checkCCCD()"
              @blur="checkCCCD()"
          />
          <Transition name="fade">
                        <span class="text-red-500 pl-5 mt-2" v-if="errorCCCD">
                            {{ $t('INVALID_ID_NUMBER') }}
                        </span>
          </Transition>
        </div>
        <GlobalVerifyForm
            class="w-full flex justify-center"
            :error="errorChecked"
            v-model:checked="isChecked"
        />

        <!-- <label
            for="checked-information"
            class="flex items-center gap-1.5 checkbox-custom lg:pl-5 flex justify-center"
        >
            <input type="checkbox" name="" class="popup" id="checked-information" v-model="" />
            <span class="text-sm leading-6 text-black/60 flex-1">
                {{ t('I_AGREE_TO_THE_POLICY_AND_TERMS') }}
            </span>
        </label> -->
      </form>
    </div>
    <template #footer>
      <!-- <button-primary :title="t('GET_OTP')" type="button" class="w-full" @click="handleSubmit()"></button-primary> -->

      <button
          class="relative w-full btn-submit-contact bg-accent-01 lg:bg-primary text-white font-semibold uppercase text-sm max-h-14 rounded-30px mt-auto py-4.5 flex items-center justify-center h-13.5 cursor-pointer"
          :class="successSubmit ? '' : 'pointer-events-none'"
          @click="handleSubmit()"
      >
        <p v-if="successSubmit">{{ t('GET_OTP') }}</p>
        <div v-else class="absolute loaderForm"></div>
      </button>
      <p
          class="text-red-700 text-15px text-center mt-2 transform duration-300 opacity-0 invisible translate-y-10"
          :class="error ? 'error-notification' : ''"
      >
        {{ t('NOT_ENOUGH_INFORMATION') }}
      </p>
    </template>
  </v-popup>
  <popup-result v-model:visible="submitFail" :title="notificationError" :intro="introError"></popup-result>
  <popup-result-fail
      v-model:visible="openPopupFail"
      :title="notificationError"
      :intro="introError"
      :is-button=true
  ></popup-result-fail>
</template>

<script lang="ts">
export default {
  name: 'popup-policy-forms'
}
</script>

<script setup lang="ts">
import {useReCaptcha} from 'vue-recaptcha-v3'
import PopupResult from './__PopupResult.vue'
import PopupResultFail from './__PopupResultFail.vue'
import {
  CHECK_CONTRACT_EVENT_ID,
  CHECK_CONTRACT_KEY,
  useEventTracking
} from '../../../composables/ackee/event'

const {t} = useI18n()
const {actionCheckExistActivePolicies} = useProducts()

const emits = defineEmits(['submitFinish', 'dataSubmit', 'closeForm', 'expiredOTP'])

const openForm = ref(true)
const errorCCCD = ref(false)
const isChecked = ref(false)
const error = ref(false)
const submitFail = ref(false)
const openPopupFail = ref(false)
const errorPhone = ref(false)
const errorChecked = ref(false)
const phone = ref<String>()
const cccd = ref()
const notificationError = ref()
const introError = ref()
const successSubmit = ref(true)
const dataRender = ref()

const closeForm = () => {
  openForm.value = false
}

watch(
    () => openForm.value,
    () => {
      if (!submitFail.value) {
        emits('closeForm')
      }
    }
)
watch(
    () => submitFail.value,
    (newValue) => {
      if (!newValue) {
        emits('closeForm')
      }
    }
)

const checkPhone = () => {
  if (phone.value !== '' && (phone.value && phone.value[0] === '0')) {
    const convertedPhoneNumber = phone.value?.substring(1);
    phone.value = '+84' + convertedPhoneNumber
  }

  const vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
  if (phone.value) {
    phone.value = phone.value.trim()
    const convertedPhoneNumber = phone.value.replace(/^\+84/, '0');
    errorPhone.value = !vnf_regex.test(convertedPhoneNumber);
  } else {
    errorPhone.value = true
  }
}

const checkChecked = () => {
  errorChecked.value = !isChecked.value;
}

watch(
    () => isChecked.value,
    () => {
      checkChecked()
    }
)

const checkCCCD = () => {
  const nid_regex = /(^(\d{9}|\d{12})$)/g;

  if (cccd.value) {
    cccd.value = cccd.value.trim()

    errorCCCD.value = !nid_regex.test(cccd.value);
  } else {
    errorCCCD.value = true
  }
}

const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
  // optional you can await for the reCaptcha load
  await recaptchaInstance?.recaptchaLoaded()

  // get the token, a custom action could be added as argument to the method
  return recaptchaInstance?.executeRecaptcha('login');
}

const handleSubmit = async (): Promise<void> => {
  checkPhone()
  checkCCCD()
  checkChecked()

  if (!errorChecked.value && !errorPhone.value && !errorCCCD.value) {
    error.value = false
    successSubmit.value = false

    useEventTracking(CHECK_CONTRACT_EVENT_ID, CHECK_CONTRACT_KEY)

    let token = await recaptcha()

    dataRender.value = await actionCheckExistActivePolicies(useRoute().params.langCode, token as string, {
      phoneNumber: phone.value as string,
      nationalId: cccd.value,
    })

    if (!dataRender.value || !dataRender.value.data.data) {
      notificationError.value = t('USER_HAS_NO_CURRENT_POLICY')
      introError.value = t('DESCRIPTION_POPUP_RESULT_POLICY')
      successSubmit.value = true
      openPopupFail.value = true
      return;
    }

    token = await recaptcha()

    await $fetch(`/api/verify/otp`, {
      method: 'POST',
      params: {
        response: token,
        type: 1,
        phoneNumber: phone.value
      }
    }).then((response) => {
      successSubmit.value = true
      if (response.code == 201) {
        emits('submitFinish', true)
        emits('dataSubmit', {
          phone: phone.value,
          cccd: cccd.value,
          time: 90
        })
        emits('expiredOTP', true)
        openForm.value = false
      } else if (response.code == 429) {
        openForm.value = false
        submitFail.value = true
        notificationError.value = t('AN_ERROR_OCCURRED_WHILE_SENDING_OTP_REQUEST')
        introError.value = t('LOOKS_LIKE_YOU_MOVING_TOO_FAST')
      } else {
        openForm.value = false
        submitFail.value = true
        notificationError.value = t('THERE_WAS_A_PROBLEM_MAKING_THE_REQUEST')
        introError.value = t('SORRY_WE_WILL_FIX_THE_PROBLEM_AS_SOON_AS_POSSIBLE_WISH_YOU_SYMPATHIZE')
      }
    })
  } else {
    error.value = true
  }
}
</script>

<style>
.error-notification {
  @apply visible translate-y-0 opacity-100;
  animation: error-animation 0.3s ease-in-out;
}

@keyframes error-animation {
  0% {
    @apply opacity-0 invisible translate-y-10;
  }
  100% {
    @apply visible translate-y-0 opacity-100;
  }
}

.checkbox-custom input[type='checkbox'].popup {
  border: var(--borderWidth) solid #d2d2d2;
}

.checkbox-custom input[type='checkbox'].popup:checked {
  border: var(--borderWidth) solid #ff8050;
}
</style>
