<template>
  <!-- {{ premium }}
  {{ totalAmountToBePaid }} -->
  <div class="p-7.5 lg:p-10 rounded-15px border border-#BFBFBF mb-10 w-full">
    <div v-for="(item, index) in infoCost">
      <div class="flex items-center justify-between gap-5 xl:gap-12.5">
        <p class="text-#404040 font-semibold">
          {{ item.title }}
        </p>
        <p
            v-if="item.content == 'months' || item.content == 'years'"
            class="xl:text-base text-primary font-semibold text-right flex-1"
        >
          <span>{{ item?.number }}&nbsp</span>
          <span>
                        {{ $t(item.content.toUpperCase()) }}
                    </span>
          <span>{{ item?.number > 1 && useRoute().params.lang == 'en' ? 's' : '' }}</span>
        </p>
        <p v-else class="xl:text-base text-primary font-semibold text-right">
          {{ item.content }}
        </p>
      </div>
      <div v-if="index < 4" class="h-1px w-full bg-black/10 my-6"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const {t} = useI18n()

const props = defineProps({
  data: {
    type: Object as any
  },
  expiredTime: {
    type: String as any
  },
  premium: Number,
  totalAmountToBePaid: Number,
  totalFees: Number
})

// const minInsuranceFee = computed(() => {
//     return props?.data?.premium?.min < props?.data?.premium?.max ? props?.data?.premium?.min : 0
// })

// const minTotalFee = computed(() => {
//     return props?.data?.sum_insured?.min < props?.data?.sum_insured?.max ? props?.data?.sum_insured?.min : 0
// })
const convertTime = () => {
  let date = new Date(props.expiredTime)

  let year = date.getUTCFullYear()
  let month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  let day = date.getUTCDate().toString().padStart(2, '0')

  let hours = date.getUTCHours().toString().padStart(2, '0')
  let minutes = date.getUTCMinutes().toString().padStart(2, '0')

  let formattedDate = `${day}-${month}-${year}`
  let formattedTime = `${hours}:${minutes}`

  return formattedDate
}

const infoCost = [
  {
    title: t('INSURANCE_FEES'),
    content: props.totalAmountToBePaid?.toLocaleString('vi-VI') + ' đ'
  },
  {
    title: t('TOTAL_FEES'),
    content: props.data.sum_insured
  },
  {
    title: t('INSURANCE_COMPANY'),
    content: props?.data?.carrier.title
  },
  {
    title: t('INSURANCE_PERIOD'),
    content: props?.data?.type_insured_period,
    number: props?.data?.insured_period
  },
  {
    title: t('EXPIRATION_DATE'),
    content: convertTime()
  }
]
</script>

<style></style>
