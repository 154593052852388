<template>
  <section-result v-if="dataRender && dataRender.length > 0" :data="dataRender"></section-result>
  <section-intro v-else @isForm="openForm"></section-intro>

  <popup-form
      v-if="isForm"
      @closeForm="isForm = false"
      @dataSubmit="dataSubmit = $event"
      @submitFinish="openOTP"
      @expiredOTP="expiredOTP = $event"
  ></popup-form>

  <popup-confirm-otp
      v-if="isOTP"
      :data="dataSubmit"
      @expiredOTP="expiredOTP = $event"
      @closeOTP="isOTP = false"
      @submitFinish="dataRender = $event"
  ></popup-confirm-otp>
</template>

<script lang="ts">
export default {
  name: 'policy-main'
}
</script>

<script setup lang="ts">
import PopupConfirmOtp from './components/__PopupConfirmOpt.vue'
import SectionIntro from './components/SectionIntro.vue'
import SectionResult from './components/SectionResult.vue'
import {useGetLangCurrent} from '../../stores/useLanguageMenu'
import PopupForm from './components/__PopupForm.vue'

// const { t } = useI18n()

const dataSubmit = ref({})
const dataRender = ref()
// const isResult = ref(false)

const isOTP = ref(false)
const isForm = ref(false)
const expiredOTP = ref(false)

const openOTP = () => {
  isForm.value = false
  isOTP.value = true
}

const openForm = () => {
  if (expiredOTP.value) {
    isOTP.value = true
  } else {
    isForm.value = true
  }
}

const settings = inject('globalSettings')
const {generateLangSwitch} = useGenerateUrl(settings)

const lang_switch = generateLangSwitch('policy', useRoute().params.langCode)
useDirectusCollectionSeo('policy_index', {})
const store = useGetLangCurrent()
onMounted(() => {
  store.lang_switch = lang_switch
})
</script>

<style></style>
