<template>
  <div class="block relative">
    <div
        :class="firstRow.length > 4 ? 'hidden lg:flex' : 'hidden'"
        class="items-center gap-3 md:gap-0 sticky top-19 xl:top-22 transform translate-y-10 lg:translate-y-9 xl:translate-y-10 justify-end mr-6.5 z-11 h-0 lg:translate-x-3 translate-x-0 text-white"
    >
      <button
          class="prev-swiper-table-product w-6 h-6 rounded-full flex flex-col items-center justify-center shadow-lg md:shadow-none duration-200 swiper-table-product"
      >
        <span class="i-ic:sharp-keyboard-arrow-left text-xl"></span>
      </button>
      <button
          class="next-swiper-table-product w-6 h-6 rounded-full flex flex-col items-center justify-center shadow-lg md:shadow-none swiper-table-product"
      >
        <span class="i-ic:sharp-keyboard-arrow-right text-xl"></span>
      </button>
    </div>
    <div v-if="firstRow && firstRow.length > 0" class="table-wrapper relative z-1 rounded-20px pb-0 bg-[#FFF4EC]">
      <div class="wrapperTable sticky top-18 md:top-20 lg:top-20 xl:top-25 z-100 bg-primary">
        <div
            :class="firstRow.length > 2 ? 'sm:hidden' : '!hidden'"
            class="absolute transform left-50 z-1000 flex items-center gap-28 justify-between"
        >
          <button
              class="prev-swiper-table-product w-10 h-18 rounded-full flex flex-col items-center justify-center duration-200 bg-primary text-white swiper-table-product"
          >
            <span class="i-ic:outline-arrow-left text-3xl"></span>
          </button>
          <button
              class="next-swiper-table-product w-10 h-18 flex flex-col items-center justify-center duration-200 bg-primary text-white swiper-table-product"
          >
            <span class="i-ic:outline-arrow-right text-3xl"></span>
          </button>
        </div>
        <div class="custom-table fake w-full sm:rounded-tl-20px sm:rounded-tr-20px relative z-100">
          <div class="relative bg-primary z-10 flex items-center px-30px md:px-0">
            <div
                class="relative min-h-19 text-white uppercase flex items-center w-50 lg:w-90 xl:w-120 pl-5 lg:pl-10 flex-none"
            >
              <div v-if="firstRow.length && firstRow.length >= 1" class="text-white text-left w-full">
                <p class="text-white text-xs font-[550] md:font-semibold xl:text-sm w-full max-w-8/10">
                  {{ $t(`${firstRow[0].toUpperCase()}`) }}
                </p>
              </div>
            </div>
            <Swiper
                @swiper="setFirstSwiper"
                :modules="[SwiperController, SwiperNavigation]"
                :space-between="20"
                :breakpoints="{
                                320: { slidesPerView: 1, slidesPerGroup: 1 }
                            }"
                :navigation="{
                                nextEl: '.next-swiper-table-product',
                                prevEl: '.prev-swiper-table-product'
                            }"
                :controller="{ control: secondSwiper }"
                :grabCursor="true"
                class="relative bg-primary z-100"
            >
              <SwiperSlide v-for="(column, index) in firstRow" :key="index" v-show="index > 0">
                <div class="text-white max-w-9/10 mx-auto lg:max-w-full">
                  <div class="text-center md:text-left">
                    <p class="text-white text-xs font-[550] md:font-semibold xl:text-sm">
                      {{ $t('PAYMENT') }}
                    </p>
                    <p
                        class="text-white text-xs font-[550] md:font-semibold xl:text-sm line-clamp-3"
                    >
                      ({{ column?.title }})
                    </p>
                  </div>
                </div>
              </SwiperSlide
              >
            </Swiper>
          </div>
        </div>
      </div>

      <div class="custom-table overflow-x-hidden w-full rounded-20px">
        <div class="flex">
          <div
              class="flex flex-col gap-2 w-50 lg:w-90 xl:w-120 py-4 pl-5 lg:pl-10 flex-none divide-y divide-bw-10"
          >
            <div
                v-for="(item, index) in firstColumn"
                :key="item.id"
                :ref="setItemRefFirstCol"
                :class="'cell-' + item.id + '-' + indexTable"
                class="py-6 space-y-3"
            >
              <div :class="'cell-parent-' + item.id + '-' + indexTable" class="flex gap-3 pr-6">
                <div
                    v-if="item.icon !== undefined"
                    class="flex-none w-10 h-10 lg:w-15 lg:h-15 rounded-full flex flex-col items-center justify-center overflow-hidden b-primary b-1"
                >
                  <img
                      :src="getThumbnail(item.icon?.id, 400)"
                      :alt="item.icon?.description || item.icon?.title"
                      class="w-full h-full object-cover"
                  />
                </div>
                <div class="">
                  <p
                      :class="'cell-parent-title-' + item.id + '-' + indexTable"
                      class="w-full font-semibold text-bw-11"
                  >
                    {{ item.title }}
                  </p>
                  <div v-if="item?.notes && item?.notes.length > 0" class="space-y-3">
                    <p
                        v-for="(noteDes, index) in item?.notes"
                        :key="index"
                        :class="'cell-parent-des-' + index + '-' + item.id"
                        class="text-xs font-medium text-bw-05"
                    >
                                            <span v-for="text in upperFirstLetter(noteDes.description)">
                                                - {{ text }}<br
                                            /></span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                  v-if="item.childs && item.childs.length > 0"
                  :key="index"
                  class="space-y-3 max-w-9/10 ml-13 lg:ml-18 pr-3 lg:pr-6"
              >
                <div
                    v-for="(child, index) in item.childs"
                    :key="child.id"
                    :class="'cell-parent-child-' + child.id + '-' + indexTable"
                >
                  <div v-if="child?.title" class="flex items-start gap-2">
                    <p class="text-sm font-semibold text-bw-11">
                      {{ child?.title }}
                    </p>
                  </div>
                  <div
                      v-if="child?.notes && child?.notes.length > 0"
                      v-for="(noteDes, index) in child?.notes"
                      :key="index"
                      class="mt-1"
                  >
                    <div v-if="noteDes?.description" class="">
                      <p class="text-xs font-medium text-bw-05">
                                                <span v-for="text in upperFirstLetter(noteDes.description)">
                                                    - {{ text }}
                                                    <br/>
                                                </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="font-semibold py-5">
              <p class="text-primary">
                {{ $t(data?.benefits_parse[props?.data?.benefits_parse.length - 1][0].toUpperCase()) }}
              </p>
            </div>
          </div>
          <Swiper
              @swiper="setSecondSwiper"
              :modules="[SwiperController, SwiperNavigation]"
              :space-between="20"
              :breakpoints="{
                            320: { slidesPerView: 1, slidesPerGroup: 1 }
                        }"
              :controller="{ control: firstSwiper }"
              :grabCursor="true"
          >
            <SwiperSlide v-if="rows && rows.length > 0" v-for="(cell, colIndex) in rows" :key="colIndex">
              <div class="flex flex-col gap-2 py-4">
                <div
                    v-if="cell && cell.length > 0"
                    v-for="(item, indexRow) in cell"
                    :key="indexRow"
                    :ref="setItemRow"
                    :class="'cell-' + item?.data?.id + '-' + indexTable"
                    class="cell-table relative text-center md:text-left"
                >
                  <div class="py-6 space-y-3">
                    <div
                        :class="'cell-parent-' + item?.data?.id + '-' + indexTable"
                        class="space-y-3"
                    >
                      <p
                          :class="'cell-parent-title-' + item?.data?.id + '-' + indexTable"
                          class="font-semibold text-bw-07 flex-1"
                      >
                        {{ item?.data?.title }}
                      </p>
                      <div v-if="item?.data?.notes && item?.data?.notes.length > 1">
                        <p
                            :class="
                                                        'cell-parent-des-' +
                                                        index +
                                                        '-' +
                                                        item?.data.id +
                                                        '-' +
                                                        indexTable
                                                    "
                            v-for="(note, index) in item?.data?.notes"
                            class="text-sm font-medium"
                        >
                          {{ note.title }}
                        </p>
                      </div>
                    </div>
                    <div
                        v-if="
                                                item?.data?.notes &&
                                                item?.data?.notes.length == 1 &&
                                                item?.data?.notes[0].title
                                            "
                    >
                      <p
                          :class="'cell-parent-des-' + 0 + '-' + item?.data.id + '-' + indexTable"
                          v-if="item?.data?.notes[0].title !== item?.data?.title"
                          class="text-sm font-medium"
                      >
                        {{ item?.data?.notes[0].title }}
                      </p>
                    </div>
                    <div
                        v-if="item?.childs && item?.childs.length > 0"
                        v-for="(child, index) in item?.childs"
                        :class="'cell-parent-child-' + child.id + '-' + indexTable"
                    >
                      <p v-if="child.title" class="text-sm font-semibold text-bw-07">
                        {{ child.title }}
                      </p>
                      <div
                          v-if="child.notes && child.notes.length > 1"
                          v-for="(note, index) in child?.notes"
                      >
                        <p v-if="note.title" class="text-sm font-medium">{{ note.title }}</p>
                      </div>
                      <div v-if="child.notes && child.notes.length == 1">
                        <p
                            v-if="child.notes[0].title && child.notes[0].title !== child?.title"
                            class="text-sm font-semibold text-bw-07"
                        >
                          {{ child?.title }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="absolute custom-line -bottom-9px left-0 w-full h-1px bg-bw-10"></div>
                </div>
                <div class="py-5 text-center lg:text-left font-semibold">
                  <span class="text-primary">{{ INSURANCE_FEES[colIndex].data.title }}</span>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const props: any = defineProps({
  data: Object,
  indexTable: Number as any
})

const upperFirstLetter = (string: string) => {
  if (!string) return ''
  const result = string.replace(/(<([^>]+)>)/gi, '').replaceAll(';', '-')
  return result.split(/\-/)
}
const itemFirtColRefs: any = ref([])
const itemRowsRefs = ref([])
const setItemRefFirstCol = (el) => {
  if (el) {
    itemFirtColRefs.value.push(el)
  }
}
const setItemRow = (el) => {
  if (el) {
    itemRowsRefs.value.push(el)
  }
}
const firstSwiper = ref(null)
const secondSwiper = ref(null)
const setFirstSwiper = (swiper) => {
  firstSwiper.value = swiper
}
const setSecondSwiper = (swiper) => {
  secondSwiper.value = swiper
}
const INSURANCE_FEES: any = computed(() => {
  let newResult: any = []
  return props?.data?.benefits_parse[props?.data?.benefits_parse.length - 1].slice(1)
})

const firstRow = computed(() => props?.data?.benefits_parse[0] || [])
const firstColumn: any = ref([])
const rows = computed(() => {
  const flatRow: any = []
  props?.data?.benefits_parse.forEach((item: any, index: number) => {
    if (index == 1) {
      firstColumn.value.push(...item)
    }
    if (index > 1 && index < props?.data?.benefits_parse.length - 1) {
      flatRow.push(item)
    }
  })
  return flatRow
})

onMounted(() => {
  // Set height rowOneInRow
  firstColumn.value.forEach((itemRef: any, indexParent: any) => {
    const listCell = document.querySelectorAll('.cell-' + itemRef?.id + '-' + props.indexTable)
    const listCellParent = document.querySelectorAll('.cell-parent-' + itemRef?.id + '-' + props.indexTable)
    const listCellParentTitle = document.querySelectorAll(
        '.cell-parent-title-' + itemRef?.id + '-' + props.indexTable
    )
    if (itemRef?.notes && itemRef?.notes.length > 0) {
      itemRef?.notes.forEach((note: any, index: any) => {
        const listCellParentDes = document.querySelectorAll(
            '.cell-parent-des-' + index + '-' + itemRef?.id + '-' + props.indexTable
        )
        const listHeight: any = []
        listCellParentDes.forEach((item) => listHeight.push(item.getBoundingClientRect().height))
        const maxHeight = Math.max(...listHeight)
        listCellParentDes.forEach((item) => (item.style.height = maxHeight + 'px'))
      })
      // const maxHeight = Math.max(...listCell.map((item: any) => item.getBoundingClientRect().height))
    }
    const listHeightCell: any = []
    const listHeightCellParent: any = []
    const listHeightCellParentTitle: any = []
    listCell.forEach((item) => {
      listHeightCell.push(item.getBoundingClientRect().height)
    })
    listCellParent.forEach((item) => listHeightCellParent.push(item.getBoundingClientRect().height))
    listCellParentTitle.forEach((item) => listHeightCellParentTitle.push(item.getBoundingClientRect().height))
    const maxHeightCell = Math.max(...listHeightCell)
    const maxHeightCellParent = Math.max(...listHeightCellParent)
    const maxHeightCellParentTitle = Math.max(...listHeightCellParentTitle)
    listCell.forEach((item) => (item.style.height = maxHeightCell + 'px'))
    listCellParent.forEach((item) => (item.style.height = maxHeightCellParent + 'px'))
    listCellParentTitle.forEach((item) => (item.style.height = maxHeightCellParentTitle + 'px'))
  })
  // Set height row

  rows.value.forEach((itemRef: any, indexParent: any) => {
    itemRef.forEach((item: any, index: any) => {
      if (item?.childs && item?.childs.length > 0) {
        item?.childs.forEach((itemSubRef: any, indexSubRef: any) => {
          const listItem = document.querySelectorAll('.cell-' + itemSubRef?.id + '-' + props.indexTable)
          const listItemChild = document.querySelectorAll(
              '.cell-parent-child-' + itemSubRef?.id + '-' + props.indexTable
          )

          const listHeight: any = []
          const listHeightChild: any = []
          listItem.forEach((item) => listHeight.push(item.getBoundingClientRect().height))
          listItemChild.forEach((item) => listHeightChild.push(item.getBoundingClientRect().height))
          const maxHeight = Math.max(...listHeight)
          const maxHeightChild = Math.max(...listHeightChild)
          listItem.forEach((item) => (item.style.height = maxHeight + 'px'))
          listItemChild.forEach((item) => (item.style.height = maxHeightChild + 'px'))
        })
      }
    })
  })
  itemFirtColRefs.value.forEach((itemRef: any, indexParent: any) => {
    const rowItems: any = []
    for (let i = indexParent; i < itemRowsRefs.value.length; i += itemFirtColRefs.value.length) {
      rowItems.push(itemRowsRefs.value[i])
    }
    const maxHeightRow = Math.max(...rowItems.map((item: any) => item.getBoundingClientRect().height))
    const maxHeightFirstCol = itemFirtColRefs.value[indexParent].getBoundingClientRect().height
    const maxHeight = maxHeightRow > maxHeightFirstCol ? maxHeightRow : maxHeightFirstCol
    itemRef.style.height = maxHeight + 'px'
    rowItems.forEach((item: any) => {
      item.style.height = maxHeight + 'px'
    })
  })
})
</script>

<style scoped>
.custom-table {
  @apply lg:overflow-x-hidden custom-scrollbar;
}

.custom-table table {
}

.custom-table table tr:first-child > td {
  @apply py-5  xl:py-7;
}

.custom-table table tr:first-child > td > p {
}

.custom-table table tr:first-child > td:not(:first-child) > p {
  @apply max-w-32;
}

.custom-table table tr td {
  @apply text-sm lg:text-15px xl:text-17px md:font-semibold text-bw-03 pl-5 md:pl-10 pr-5 pt-3 pb-3 md:pt-9 md:pb-7.5;
}

.custom-table table tr {
  @apply rounded-tl-xl;
}

.custom-table table tr > td:first-child {
  @apply pl-5 md:pl-10 text-left w-50 sm:w-60 lg:w-80 xl:w-85;
}

.custom-table.fake table tr:first-child > td:first-child {
  @apply bg-primary;
  vertical-align: middle;
}

.custom-table.fake table tr > td:first-child,
.custom-table table tr > td:first-child {
  @apply sticky left-0 bg-[#FFF4EC];
}

.custom-table table tr:first-child > td:not(:first-child) {
  @apply text-right lg:text-left text-sm  w-50 md:w-62 lg:w-57 xl:w-58;
}

.custom-table table tr td {
  @apply text-right lg:text-left;
  vertical-align: top;
}

/*-----------------Scroll overflow text--------------------*/
.wrapperTable::before {
  content: '';
  position: absolute;
  @apply w-10 h-10 bg-white top-0 left-0 z-1;
}

.wrapperTable::after {
  content: '';
  position: absolute;
  @apply w-full h-10 bg-white -top-15px right-0 z-1;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}

.table-wrapper.active::after {
  content: ' ';
  @apply block lg:hidden;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  position: absolute;
  width: 30px;
  border-radius: 0px 20px 20px 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 10;
}

.custom-table.fake.active::after {
  content: ' ';
  @apply block lg:hidden;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  position: absolute;
  width: 20px;
  border-radius: 0px 20px 0px 0px;
  height: 100%;
  bottom: 0px;
  right: 0px;
}

.custom-line:after {
  content: '';
  @apply absolute -right-5 w-10 h-1px bg-bw-10;
}

.swiper-table-product.swiper-button-disabled {
  @apply text-white/30;
}
</style>
