<template>
  <v-popup overlay closeOverlay onOpenLoadPage keyPopup="popupPolicyResult" :closeable="false">
    <template #header>
            <span
                class="i-custom-chevron-right transform rotate-180 w-5.5 h-5.5 text-black/50 absolute z-5 top-4.5 left-4.5 cursor-pointer"
                @click="$emit('update:visible', false)"
            ></span>
      <div class="flex justify-center mb-6">
        <div class="w-15.5 h-15.5">
          <img src="/images/global/icon-popup-analysis.svg" alt="icon" class="w-full h-full object-contain"/>
        </div>
      </div>
    </template>
    <div class="flex flex-col gap-3">
      <p class="text-#121212 text-25px leading-10 font-[550] text-center">
        {{ title }}
      </p>
      <p class="text-black/60 leading-6 text-15px text-center">
        {{ intro }}
      </p>
    </div>

    <template v-if="isButton" #footer>
      <button-primary :title="t('PRODUCT_VIEW')" :url="t('ROUTER_PRODUCT')" class="w-full mt-11"></button-primary>
    </template>
  </v-popup>
</template>
<script lang="ts">
export default {
  name: 'popup-policy-result'
}
</script>
<script setup lang="ts">
const {t} = useI18n()
defineProps({
  title: {
    type: String as any
  },
  intro: {
    type: String as any
  },
  isButton: {
    type: Boolean
  }
})
</script>

<style>
.box-content-popup-popupPolicyResult {
  @apply lg:!w-400px;
}
</style>
