<template>
  <section class="container py-10 lg:py-20">
    <div class="flex xl:flex-row flex-col gap-10 xl:gap-34">
      <div class="flex flex-col xl:max-w-123">
        <v-text
            data-aos="fade-right"
            data-aos-once="true"
            tag="p"
            class="text-sm text-bw-09/50 leading-6 uppercase mb-3 font-medium"
            option_key="policyPage.subtitle"
        ></v-text>
        <div
            data-aos="fade-right"
            data-aos-once="true"
            class="text-3xl lg:text-48px leading-4xl lg:leading-60px font-[550] text-bw-03 mb-8"
        >
          <v-content
              default_value="Kiểm tra thông tin hợp đồng <p class='text-base text-black/60 leading-6 mt-4.5 font-normal'> Cảm ơn bạn đã quan tâm đến bảo hiểm IZIon24. Hãy cho chúng tôi biết bạn đang cần được hỗ trợ sản phẩm nào nhé!</p>"
              option_key="policyPage.contentIndex"
          ></v-content>
        </div>

        <button
            data-aos="fade-up"
            data-aos-once="true"
            class="px-8 py-3 rounded-23px uppercase duration-400 font-semibold flex justify-center items-center cursor-pointer bg-primary hover:bg-accent-01 text-bw-01 btn-effect mb-16 md:w-max w-full"
            @click="$emit('isForm', true)"
        >
          <span>{{ t('CHECK_NOW') }}</span>
        </button>
        <ul data-aos="fade-up" data-aos-once="true" class="flex gap-8.5">
          <li class="flex flex-col gap-3">
            <div class="w-10 aspect-1/1 image-contain">
              <v-image
                  option_key="policyPage.imageItem1"
                  class="w-full h-full object-contain rounded-2"
                  :width="80"
              >
              </v-image>
            </div>
            <v-text
                tag="p"
                class="font-[550] leading-6 tracking-0.16px text-accent-03 text-base"
                option_key="policyPage.titleItem1"
            ></v-text>
          </li>
          <li class="flex flex-col gap-3">
            <div class="w-10 aspect-1/1 image-contain">
              <v-image
                  option_key="policyPage.imageItem2"
                  class="w-full h-full object-contain rounded-2"
                  :width="80"
              >
              </v-image>
            </div>
            <v-text
                tag="p"
                class="font-[550] leading-6 tracking-0.16px text-accent-03 text-base"
                option_key="policyPage.titleItem2"
            ></v-text>
          </li>
          <li class="flex flex-col gap-3">
            <div class="w-10 aspect-1/1 image-contain">
              <v-image
                  option_key="policyPage.imageItem3"
                  class="w-full h-full object-contain rounded-2"
                  :width="80"
              >
              </v-image>
            </div>
            <v-text
                tag="p"
                class="font-[550] leading-6 tracking-0.16px text-accent-03 text-base"
                option_key="policyPage.titleItem3"
            ></v-text>
          </li>
        </ul>
      </div>
      <div data-aos="fade-left" data-aos-once="true" class="flex-1 flex justify-center xl:justify-start">
        <div class="w-150 xl:w-full aspect-1/1">
          <v-image option_key="policyPage.image" class="w-full h-full object-cover rounded-15px" :width="900">
          </v-image>

          <div class="absolute -bottom-10 right-0 lg:right-10 xl:right-auto xl:-left-20">
            <img
                src="/images/global/masgotRight.png"
                alt="masgot"
                class="w-30 md:w-42 xl:w-56 aspect-56/44 object-contain animationSway"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
export default {
  name: 'intro-policy-main'
}
</script>

<script setup lang="ts">
const {t} = useI18n()

defineEmits(['isForm'])
</script>

<style></style>
