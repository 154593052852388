<template>
  <v-popup v-model:visible="openOTP" overlay closeOverlay keyPopup="popupOtp" :closeable="false">
    <template #header class="mb-7.5 relative">
            <span
                class="i-custom-close2 w-3 h-3 text-bw-24 absolute z-5 top-4.5 right-4.5 cursor-pointer"
                @click="closeOTP"
            ></span>
      <h5 class="text-32px font-[550] leading-10 text-center text-black mb-3">
        <span> {{ $t('CONFIRM') }} </span>
        <span class="text-primary pl-2">OTP</span>
      </h5>
      <p v-if="data?.email" class="text-center text-black/60 text-15px leading-6">
        {{ $t('WE_HAVE_SENT_THE_OTP_TO_THE_EMAIL') }} {{ data?.email }}
      </p>
      <p v-if="data?.phone" class="text-center text-black/60 text-15px leading-6">
        {{ $t('WE_HAVE_SENT_THE_OTP_TO_THE_PHONE_NUMBER') }} {{ data?.phone }}
      </p>
    </template>
    <form @submit.prevent="handleSubmit" class="mb-6.5 mt-7.5">
      <div class="w-full box-opt">
        <v-otp-input
            ref="otpInput"
            v-model:value="bindModal"
            input-classes="otp-input"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            input-type="letter-numeric"
            :conditionalClass="['one', 'two', 'three', 'four', 'five', 'six']"
            shouldautofocus
            @on-complete="handleOnComplete"
        />
        <p class="text-13px leading-6 text-bw-23 mt-6 text-center gap-1 flex justify-center">
          <span> {{ $t('THE_CODE_WILL_EXPIRE_AFTER') }} </span>
          <span class="text-primary">{{ time }}s</span>
        </p>
      </div>
    </form>
    <template #footer>
      <!-- <button-primary :title="$t('CONFIRM_NOW')" class="w-full" @click="handleSubmit()"></button-primary> -->
      <button
          class="relative w-full btn-submit-contact bg-accent-01 lg:bg-primary text-white font-semibold uppercase text-sm max-h-14 rounded-30px mt-auto py-4.5 flex items-center justify-center min-h-56px cursor-pointer"
          :class="successSubmit ? '' : 'pointer-events-none'"
          @click="handleSubmit()"
      >
        <p v-if="successSubmit">{{ $t('CONFIRM_NOW') }}</p>
        <div v-else class="absolute loaderForm"></div>
      </button>
    </template>
  </v-popup>

  <popup-result v-model:visible="submitFail" isButton :title="notificationError" :intro="introError"></popup-result>
  <popup-result-fail
      v-model:visible="openPopupFail"
      :title="notificationError"
      :intro="introError"
  ></popup-result-fail>
</template>

<script lang="ts">
export default {
  name: 'popup-policy-otp'
}
</script>

<script setup lang="ts">
import {useReCaptcha} from 'vue-recaptcha-v3'
import nuxtStorage from 'nuxt-storage'
import VOtpInput from 'vue3-otp-input'
import PopupResult from './__PopupResult.vue'
import PopupResultFail from './__PopupResultFail.vue'
import {
  VIEW_POLICIES_EVENT_ID,
  VIEW_POLICIES_KEY,
  useEventTracking
} from "../../../composables/ackee/event";

const {t} = useI18n()
const {actionGetPolicies} = useProducts()

const props = defineProps({
  data: {
    type: Object
  }
})
const emits = defineEmits(['closeOTP', 'submitFinish', 'expiredOTP'])

const openOTP = ref(true)
const successSubmit = ref(true)
const submitFail = ref(false)
const openPopupFail = ref(false)
const isStart = ref(true)
const refreshInterval = ref()

const otpInput = ref<InstanceType<typeof VOtpInput> | null>(null)
const bindModal = ref('')
const OTP = ref()
const notificationError = ref()
const introError = ref()
const dataRender = ref()

const handleOnComplete = (value: string) => {
  OTP.value = value
}

const time_local = ref(nuxtStorage.localStorage.getData('time_otp_policy_' + props.data?.phone))
const time = ref(time_local.value ? time_local.value : props.data?.time)

const closeOTP = () => {
  openOTP.value = false
}

watch(
    () => openOTP.value,
    (newValue) => {
      if (!submitFail.value && !openPopupFail.value) {
        emits('closeOTP')
      }
    }
)

watch(
    () => submitFail.value,
    (newValue) => {
      if (!newValue) {
        emits('closeOTP')
      }
    }
)
watch(
    () => openPopupFail.value,
    (newValue) => {
      if (!newValue) {
        emits('closeOTP')
      }
    }
)
const startOTP = () => {
  if (time.value > 0) {
    time.value--
    nuxtStorage.localStorage.setData('time_otp_policy_' + props.data?.phone, time.value, 2)
  } else {
    nuxtStorage.localStorage.removeItem('time_otp_policy_' + props.data?.phone)
    emits('closeOTP', false)
    emits('expiredOTP', false)
    notificationError.value = t('THE_OTP_CODE_HAS_EXPIRED')
    introError.value = t('PLEASE_RESUBMIT_YOUR_REQUEST_TO_GET_A_NEW_OTP')
  }
}

const removeLocal = () => {
  isStart.value = false
  emits('expiredOTP', false)
  clearInterval(refreshInterval.value)
  nuxtStorage.localStorage.removeItem('time_otp_policy_' + props.data?.phone)
}

const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
  // optional you can await for the reCaptcha load
  await recaptchaInstance?.recaptchaLoaded()

  // get the token, a custom action could be added as argument to the method
  return recaptchaInstance?.executeRecaptcha('login');
}

const handleSubmit = async () => {
  if (OTP.value && OTP.value.length === 6) {
    successSubmit.value = false
    const token = await recaptcha()
    dataRender.value = await actionGetPolicies(useRoute().params.langCode, token as string, {
      phoneNumber: props.data?.phone,
      nationalId: props.data?.cccd,
      otp: OTP.value
    })

    successSubmit.value = true

    if (dataRender.value?.code && dataRender.value?.code != 200 && dataRender.value?.code != 201) {
      if (dataRender.value?.code == 500) {
        openOTP.value = false
        openPopupFail.value = true
        notificationError.value = t('THE_OTP_YOU_ENTERED_IS_NOT_CORRECT')
        introError.value = t('PLEASE_DOUBLE_CHECK_THE_RECEIVED_OTP')
      } else {
        openOTP.value = false
        openPopupFail.value = true
        notificationError.value = t('THERE_WAS_A_PROBLEM_MAKING_THE_REQUEST')
        introError.value = t('SORRY_WE_WILL_FIX_THE_PROBLEM_AS_SOON_AS_POSSIBLE_WISH_YOU_SYMPATHIZE')
      }
      removeLocal()
    } else {
      useEventTracking(VIEW_POLICIES_EVENT_ID, VIEW_POLICIES_KEY)
      OTP.value = null
      if (dataRender.value && dataRender.value.length > 0) {
        emits('submitFinish', dataRender.value)
        openOTP.value = false
      } else {
        notificationError.value = t('USER_HAS_NO_CURRENT_POLICY')
        introError.value = t('DESCRIPTION_POPUP_RESULT_POLICY')
        openOTP.value = false
        submitFail.value = true
      }
      removeLocal()
    }
  }
}

onMounted(() => {
  if (time.value > 0 && isStart.value) {
    refreshInterval.value = setInterval(() => {
      startOTP()
    }, 1000)
  }
})
</script>

<style></style>
