<template>
  <section class="py-10 lg:py-20 flex flex-col gap-10 lg:gap-20">
    <template v-for="(item, index) in data">
      <div class="flex lg:flex-row flex-col justify-between gap-10 lg:gap-20 xl:gap-30 w-full container">
        <div class="flex flex-col">
          <p class="text-bw-09/50 leading-5 text-sm font-medium uppercase mb-2">
            {{ $t('YOU_ARE_PROTECTED_WITH') }}
          </p>
          <h1 class="text-2xl leading-3xl lg:text-30px lg:leading-44px font-[550] text-primary mb-8">
            {{ item?.product?.title }}
          </h1>

          <box-result
              :data="item?.product"
              :premium="item?.premium"
              :totalAmountToBePaid="item?.totalAmountToBePaid"
              :expiredTime="item?.policyExpiringDate"
              :totalFees="item?.product?.plans[0]?.sum_insured"
          ></box-result>

          <!-- <p class="!hidden" v-text="calculateDateDifference(item?.policyExpiringDate)"></p> -->
          <div class="flex flex-col gap-3">
            <template
                v-if="
                                calculateDateDifference(item?.policyExpiringDate).type == 1 ||
                                calculateDateDifference(item?.policyExpiringDate).type == 2
                            "
            >
              <h3 class="lg:text-3xl lg:leading-44px text-2xl leading-3xl text-error-01 font-[550]">
                {{ $t('NOTIFICATION') }}
              </h3>
              <p
                  v-if="calculateDateDifference(item?.policyExpiringDate).type == 1"
                  class="space-x-1 font-medium"
              >
                <span>{{ $t('INSURANCE_PERIOD_FROM') }}</span>
                <span>{{ convertTime(item?.policyEffectiveDate) }}</span>
                <span>{{ $t('TO') }}</span>
                <span>{{ convertTime(item?.policyExpiringDate) }}</span>
              </p>
              <p v-else class="text-bw-27 space-x-1 font-medium">
                <span>{{ $t('YOUR_POLICY_WILL_EXPIRE_IN_THE_NEXT') }}</span>
                <span
                    class="text-error-01"
                    v-text="calculateDateDifference(item?.policyExpiringDate).value"
                >
                                </span>
                <span>{{ $t('PLEASE_ENSURE_YOUR_RENEWAL') }}</span>
              </p>
              <button-primary
                  v-if="calculateDateDifference(item?.policyExpiringDate).type == 2"
                  :title="t('RESIDENCY')"
                  :url="item?.product.onelink_url"
                  class="!w-full !lg:w-max mt-4"
              ></button-primary>
            </template>
            <template v-else>
              <h3 class="lg:text-3xl lg:leading-44px text-2xl leading-3xl text-error-01 font-[550]">
                {{ $t('OVERDATE_CONTRACT') }}
              </h3>
              <p class="text-error-01 font-medium">{{ $t('YOUR_CONTRACT_HAS_EXPIRED_FOR_RENEWAL') }}</p>
              <button-primary
                  :title="$t('NEW_PURCHASE')"
                  :url="$t('PRODUCT_URL')"
                  class="!w-full !lg:w-max mt-4"
              ></button-primary>
            </template>
          </div>
          <!-- <p class="text-black/60 mb-6">
              <span>{{ $t('YOUR_CONTRACT_IS_STILL') }}</span>
              <span
                  class="font-[550]"
                  v-text="timeRemaining(item?.policyEffectiveDate, item?.policyExpiringDate)"
              ></span>
              <span>{{ $t('IT_EXPIRED') }}</span>
          </p> -->
        </div>
        <div class="flex flex-col min-w-1/2 lg:w-1/2 !lg:block !hidden">
          <p class="text-bw-09/50 leading-5 invisible text-sm font-medium uppercase mb-2">
            {{ $t('YOU_ARE_PROTECTED_WITH') }}
          </p>
          <h1
              class="text-2xl leading-3xl lg:text-30px lg:leading-44px font-[550] text-primary mb-8 max-w-130 invisible"
          >
            {{ item?.product?.title }}
          </h1>
          <table-info-cost
              class="flex-none w-full"
              :data="item?.product"
              :indexTable="index + 1"
          ></table-info-cost>
        </div>
      </div>
      <table-info-cost
          class="flex-none w-full !block !lg:hidden"
          :indexTable="index + 1"
          :data="item?.product"
      ></table-info-cost>
    </template>
  </section>

  <section-download></section-download>
</template>
<script lang="ts">
export default {
  name: 'policy-result'
}
</script>

<script setup lang="ts">
import SectionDownload from '../../mobile-app/components/SectionDownload.vue'
import TableInfoCost from './TableInfoCost.vue'
import BoxResult from './__BoxResult.vue'

const {t} = useI18n()
const props = defineProps({
  data: {
    type: Array as any
  }
})
const convertTime = (time: any) => {
  let date = new Date(time)

  let year = date.getUTCFullYear()
  let month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  let day = date.getUTCDate().toString().padStart(2, '0')

  let hours = date.getUTCHours().toString().padStart(2, '0')
  let minutes = date.getUTCMinutes().toString().padStart(2, '0')

  let formattedDate = `${day}-${month}-${year}`
  let formattedTime = `${hours}:${minutes}`

  return formattedDate + ' ' + formattedTime
}

const calculateDateDifference = (endTime: any) => {
  let typeContract = 0
  let dayNow = new Date()
  let endDate = new Date(endTime)
  let timeDifference = endDate - dayNow

  let daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

  if (daysDifference > 30) {
    typeContract = 1
  } else if (daysDifference < 30 && daysDifference > 0) {
    typeContract = 2
  } else {
    typeContract = 3
  }
  return {
    type: typeContract,
    value: daysDifference + ' ' + t('DAY')
  }
}

// const timeRemaining = (timeBegin: any, timeEnd: any) => {
//     const startTime = new Date(timeBegin)
//     const endTime = new Date(timeEnd)
//     const totalSeconds = Math.floor((endTime - startTime) / 1000)

//     const years = Math.floor(totalSeconds / (3600 * 24 * 365))
//     const months = Math.floor((totalSeconds % (3600 * 24 * 365)) / (3600 * 24 * 30))
//     const days = Math.floor((totalSeconds % (3600 * 24 * 30)) / (3600 * 24))

//     if (years <= 0 && months <= 1) {
//         checkTime.value = true
//     } else {
//         checkTime.value = false
//     }
//     let result = ''
//     if (years) {
//         result += years + ' ' + t('YEAR') + ', '
//     }
//     if (months) {
//         result += months + ' ' + t('MONTH') + ', '
//     }
//     if (days) {
//         result += days + ' ' + t('DAY')
//     }
//     return result
// }
</script>

<style>
.custom-table-policy table tr td {
  @apply !w-52 !px-12.5;
}
</style>
